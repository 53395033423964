import BlogUpdate from "../../../forms/blog-updates/BlogUpdate";
import { useEffect } from "react";
import "./EmailLandingPage.css"
import shortStoryAd from '../../../../assets/images/shortStoryAd.jpg'


export default function EmailLandingPage() {
    useEffect(() => {
        document.title = 'Exclusive Content - Scott A. Young';
    }, []);


    return (
        <main className="landing-page">
            <img className='short-story-ad' src={shortStoryAd} alt="The Other Woman. A tale from the Manifold series."></img>
            <h3 className="landing-page-title">Claim your exclusive free copy of <em>The Other Woman </em><br/>and recieve new release updates by joining my Mailing List.</h3>
            <BlogUpdate></BlogUpdate>
            <p className="disclaimer-label">You can unsubscribe at anytime.</p> 
        </main>
        // add a link to privacy policy whent that is done
    )
}