import "./Chyron.css"
import { createElement } from "react";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';

export default function Chyron({ displayItems }) {
    let blurbs = [];

    for (var item of displayItems) {
        let children = [];
        if (item.shortBlurb && item.source) {
            let shortBlurb = "<p className=\"chyron-short-blurb " + item.id + "\">\"" + item.shortBlurb + "\"</p>";
            let source = "<p className=\"chyron-source " + item.id + "\"><strong>&mdash;" + item.source + "</strong></p>";
            children.push(parse(shortBlurb));
            children.push(parse(source));
        }
        else {
            children.push(parse(item));
        }
        // console.log(children);

        let newBlurb = createElement('div', { className: "chyron-blurb" }, children);
        blurbs.push(newBlurb);
    }

    let nextQuote = blurbs.shift(); //Pull the top one
    blurbs.push(nextQuote); //And add it back to the end
    const [curQuote, setCurQuote] = useState(nextQuote);

    useEffect(() => {
        const interval = setInterval(() => {


            let nextQuote = blurbs.shift(); //Pull the top one
            blurbs.push(nextQuote); //And add it back to the end
            // document.getElementById('chyron').innerHTML = curQuote;
            // console.log(nextQuote);
            setCurQuote(nextQuote);

            // console.log("yes");
        }, 4000);
        return () => clearInterval(interval);
    }, [])





    return (
        <>
            <div id="chyron">
                {curQuote}
            </div>
        </>
    )
}