import './IndividualBook.css'
import BookLayout from '../book-layout/BookLayout'
import { useEffect } from 'react';
export default function IndividualBook({book}) {

    useEffect(() => {
        document.title = book.bookName + " - Scott A. Young";
      });

      
    return (
        <div className='individual-book-page-content'>
            <BookLayout id={book.id} bookName={book.bookName} subtitle={book.subtitle} coverImage={book.coverImage} blurb={book.blurb} last={true} hasLink={false} blurbNoVanish={true} linksToBuy={book.linksToBuy}/>
            
        </div>
    )
}