import { Link } from "react-router-dom";
import './BlogLayout.css'
import parse from 'html-react-parser';

export default function BlogLayout({ element, last, hasLink }) {
    let blogContent;
    if (hasLink) {
        var pathToPage = "/blog/" + element.title.toLowerCase().replace(" ", "-");
        blogContent = element.content.slice(0, 200) + ". . .";
    }
    else {
        blogContent = element.content;
    }

    let paras;
    if (blogContent) {
        let blurbParagraphs = blogContent.split("\n")
        paras = []
        for (let blurbParagraph of blurbParagraphs) {
            // console.log(blurbParagraph)
            let para = "<p>".concat(  blurbParagraph,  "<br/></p>")
            // console.log(parse(para))
            paras.push(parse(para))
        }

    }
    else {
        paras = <p>{blogContent}</p>;
    }
    if (element.date) {
        var hasDate = true;
    }
    else {
        hasDate = false;
    }
    let contents = <div className="blog-layout">
        <div className="blog-title-container">
            {hasDate && <p className="date-title">{element.date}</p>}
            <h1 className="blog-title">{element.title}</h1>
            
        </div>
        
        <h2 className="blog-subtitle">{element.subtitle}</h2>
        <div className="blog-content">{paras}</div>
    </div>
    return (
        <>
        <div className="blog-layout-container">
            {hasLink && <Link to={pathToPage} className="blog-link">
                {contents}
            </Link>}
            {!hasLink && <>{contents}</>}
            <br></br>
            {/* {!last && <hr />} */}
            </div>
        </>
    )
}