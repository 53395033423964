import './About.css'
import BookLayout from '../../page-content/books/book-layout/BookLayout';
import { useEffect } from 'react';
import { bio } from '../../../..';
import { Link } from 'react-router-dom';
export default function AboutContent({ isHomePage }) {

    useEffect(() => {
        document.title = 'About - Scott A. Young';
    }, []);
    let authorTagline = "";
    let authorImage = "https://scottayoungauthor.com/assets/ScottAYoung-Pic-Preferred-2.jpg"

    // console.log(bio)

    let authorBio = bio
    // console.log(bio);

    let authorLayout = <BookLayout coverImage={authorImage} blurb={authorBio} bookName={authorTagline} last={true} hasLink={false} blurbNoVanish={true} />
    if (isHomePage) {

        authorLayout = <Link className="author-bio-link" to="/about" ><BookLayout coverImage={authorImage} blurb={authorBio} bookName={authorTagline} last={true} hasLink={false} blurbNoVanish={false} /></Link>

    }

    function downloadMediaKit() {
        download("https://scottayoungauthor.com/assets/MediaKit.zip")
        return false;
    }
    function download(url) {
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }


    // let last = !isHomePage
    return (
        <div className="about-content-container">

            {authorLayout}
            {/* <h3>Check me out on GoodReads</h3> */}
            <form className="link-to-buy" action="https://www.goodreads.com/author/show/48194506.Scott_A_Young" target="_blank"><button className="link-btn" type="submit">GoodReads</button></form>
            {/* {!last && <hr></hr>} */}
            {!isHomePage && <div className="media-kit-container">
                <p className="media-kit-description">Download the media kit for Author Bio, Book Details, Character Profiles, Chapter Excerpt, Sample Interview Questions and more</p>
                <form className="link-to-buy" action=""><button className="link-btn" type="button" onClick={downloadMediaKit}>Download Media Kit</button></form>
            </div>}
        </div>

    )
}