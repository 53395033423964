import BlogUpdate from '../forms/blog-updates/BlogUpdate';
import './Footer.css'
// import SocialLinkList from '../social-icon/SocialIconList';
import NavLinkList from '../header/NavigationLink/NavLinkList';
import FooterColumn from './footer-column/FooterColumn';

export default function Footer({linkedPages, linkedSocials}) {

    return (
        <footer className="App-footer">
            <hr className="page-break"/>
            <div className="footer-columns">
                <FooterColumn columnTitle={"Other Pages"} columnLinkList={linkedPages} />
                
                <FooterColumn columnTitle={"Follow Me"} columnOtherElements={<NavLinkList linkedPages={linkedSocials} isFlex={false}/>} />
    
                <FooterColumn columnTitle={"Join My Mailing List"} columnOtherElements={<BlogUpdate/>}/>
    
            </div>
            {/* <div className="social-icon-links">
                <span><a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com/icon/ud9VVQzOPag8/twitterx">TwitterX</a> icon by <a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com">Icons8</a></span>
                <span><a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com/icon/118490/facebook">Facebook</a> icon by <a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com">Icons8</a></span>
                <span><a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com/icon/84884/instagram">Instagram</a> icon by <a className="social-ico-link" target="_blank" rel="noreferrer" href="https://icons8.com">Icons8</a></span>
            </div> */}
            <div className="info-text-group">
            
                <p className="copyright-notice">&copy; <strong><a id="author-name-link" href="https://scottayoungauthor.com" >Scott A. Young</a></strong> All Rights Reserved</p>
                <p className="creator-text">Site created by: Graham Young</p>
            </div>
            
        </footer>
    )
}