import { useState } from 'react'
import './BlogUpdate.css'


export default function BlogUpdate() {
    const [formData, setFormData] = useState({"subscribe": "true"});
    const [displaySubscribed, setSubscribedMessage] = useState(false)
    const [displayPending, setPendingMessage] = useState(false);
    const [displayInvalidEmail, setInvalidEmailMessage] = useState(false);

    function handleChange(e) {
        const {name, value} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    
    // let fetchUrl = "https://scottayoungauthor.com/testing"
    // let fetchUrl = process.env.REACT_APP_BACKEND_ADDRESS + "/mailchimp.php"
    let fetchUrl = '/server/mailchimp.php'
    
    async function run() {
        console.log(JSON.stringify(formData))
        fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": "http://localhost:3000"
            },
            body: JSON.stringify(formData),
            
        })
        // .then(response => console.log(response))
        .then(response => response.json())
        .then(jsonData => {
            // console.log(jsonData);
            if (jsonData.status === "subscribed") {
                setSubscribedMessage(true);
                // console.log(false);
                
            }
            else if (jsonData === "") {
                console.log("error");
            }
            else if (jsonData === "Invalid email format") {
                setInvalidEmailMessage(true);
            }
            else {
                setPendingMessage(true);
                // console.log(true);
                
            }
        })
            
        .catch(error => console.error(error));
        
      
    }

    async function handleSubmit(e) {
        setSubscribedMessage(false);
        setPendingMessage(false);
        setInvalidEmailMessage(false);
        e.preventDefault();
        document.getElementById("submit-button").disabled = true
        await run();
        
        setFormData({"subscribe": "true"});
        setTimeout(() => {
            setInvalidEmailMessage(false);
            setPendingMessage(false);
            setSubscribedMessage(false)
        }, 15000)
    }

    function StatusSubscribed() {
        return( <p id="submit-success-message" className="submit-message success">You are already subscribed.</p> )
    }
    function StatusPending() {
        return ( <p id="submit-success-message" className="submit-message success">Check your email for confirmation.</p> )
    }

    function InvalidEmail() {
        return ( <p id="submit-failed-message" className="submit-message failed">Invalid email address.</p>)
    }

    //TODO update css for messages

    return (
        <form id="email-form" onSubmit={handleSubmit}> 
            <div className="form-field">
                <input type="email" id="email-field" name="email-address" placeholder='Enter your Email' required onChange={handleChange}/>
            </div>
            {displaySubscribed && <StatusSubscribed />}
            {displayPending && <StatusPending />}
            {displayInvalidEmail && <InvalidEmail/>}
            <div className="form-field">
                <button type="submit" className="btn" id="submit-button" >Submit</button>
            </div>
            
        </form>
    )
}

