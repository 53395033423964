import { Link } from "react-router-dom"
import BlogLayout from "../blog-layout/BlogLayout"
import './IndividualBlogPost.css'
// import CommentSection from "../comment-section/CommentSetion"

export default function IndividualBlogPost({ blogPost, nextPost, prevPost }) {

    return (
        <div className="individual-blog-contents">
        <BlogLayout element={blogPost} hasLink={false} last={true}/>
        <div className="other-posts">
         {nextPost && <Link className="next-post" to={"/blog/" + nextPost.title.toLowerCase().replace(" ", "-")}>Next &#8212;&gt;</Link>}
         {prevPost && <Link className="last-post" to={"/blog/" + prevPost.title.toLowerCase().replace(" ", "-")}>&lt;&#8212; Previous </Link>}
         </div>
         {/* <CommentSection blogId={blogPost.id}/> */}
        </div>
    )
}