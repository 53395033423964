import SocialLink from "./SocialIcon";
import './SocialIconList.css'


export default function SocialLinkList({linkedSocials, isFlex}) {
    let className = isFlex ? " social-flex" : " social-block"
    let links = linkedSocials.map(function (element) {
        return <SocialLink id={element.id} link={element.link} name={element.name} icon={element.icon}/>
    });
    return (
        <ul className={"social-links" + className}>
            {links}
        </ul>
    )
}