import "./Blogs.css"
import BlogLayout from "./blog-layout/BlogLayout";
import { useEffect } from "react";
export default function BlogContent({blogPosts}) {
    // let posts = JSON.parse(blogPosts) 
    

    useEffect(() => {
        document.title = 'Blog - Scott A. Young';
      }, []);

    let postsParsed = blogPosts.map((element, i, {length}) => {
        let last = i === length - 1;
        // return !last ? (<><BlogLayout element={element} last={last} hasLink={true}/><div className="page-spacer blog-spacer"></div></>) : (<BlogLayout element={element} last={last} hasLink={true}/>) 
        return <BlogLayout element={element} last={last} hasLink={true}/>
    })

    return (
        <>
        {postsParsed}
        </>
    )
}