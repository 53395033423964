import './ContactForm.css'
import { useState } from 'react';


export default function ContactForm() {
    const [formData, setFormData] = useState({__html: ""});
    const [data, setData] = useState(null);
    const [showResponseMessage, setShowResponseMessage] = useState(false);

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    // const fetchUrl = process.env.REACT_APP_BACKEND_ADDRESS + "/contactForm.php"
    let fetchUrl = '/server/contactForm.php'

    async function handleSubmit(e) {
        setShowResponseMessage(false);
        e.preventDefault();
        document.getElementById("submit-button").disabled = true
        await run();


        setFormData({});
        return false;
    }

    // function ResponseMessage({message}) {
    //     <div className='response-message'>
    //         <p>{message}</p>
    //     </div>
    // }
   
    async function run() {
        console.log(JSON.stringify(formData))
        

        let resposne = await fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),

        })
        let responseData = await resposne.text();
        // console.log(responseData)
        setData({__html: responseData})
        // console.log(responseMessage)
        setShowResponseMessage(true);

            // .catch((SyntaxError) => {
            //     alert("Something went wrong");
            // })

    }
    return (

        <>
            {!showResponseMessage &&
                <form method="post" id="contact-form" onSubmit={handleSubmit}>
                    <h1>Contact Us</h1>
                    <div className="form-field contact-field">
                        <label>Name (required)</label>
                        <input required name="subject" type="text" onChange={handleChange} />
                    </div>
                    <div className="form-field contact-field">
                        <label>Email (required)</label>
                        <input required name="email" type="email" onChange={handleChange} />
                    </div>
                    <div className="form-field contact-field">
                        <label>Message</label>
                        <textarea name="message" onChange={handleChange}></textarea>
                    </div>
                    <input type="checkbox" id="subscribe" name="subscribe" value="1" onChange={handleChange}></input>
                    <label htmlFor="subscribe" className='checkbox-label'>Subscribe to my mailing list.</label>


                    <div className="form-field contact-field">
                        <button className="btn" id="submit-button" type="submit">Send</button>
                    </div>
                </form>
            }
            <div dangerouslySetInnerHTML={data}/>
            

        </>


    )
    //TODO add sub to mailing list option
}