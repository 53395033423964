import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import parse from 'html-react-parser';

function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object", 
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }

  return false;
};

async function makeListFetch(url) {
  
  let res = await fetch(url);
  let outputJson = await res.json();

  let output = tryParseJSONObject(outputJson);
  if (!output) {
    output = tryParseJSONObject(JSON.stringify(outputJson));
  }


  return output;

}
// let bio;
// let bioRes = await fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/bio.php")
//   .then(response =>  console.log(response.text()))
//   .finally((responseData) => bio = responseData);
// let bioUrl = process.env.REACT_APP_BACKEND_ADDRESS + "/bio.php"
let res = await fetch('/server/bio.php');
let bioText = await res.text();
export const bio = bioText

// console.log(bio);

// let bookListUrl = process.env.REACT_APP_BACKEND_ADDRESS + "/books.php"
export let bookList = await makeListFetch('/server/books.php')

// let blogListUrl = process.env.REACT_APP_BACKEND_ADDRESS + "/blogs.php"
export let blogList = await makeListFetch('/server/blogs.php')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
