import './ImageLink.css'
import { Link } from 'react-router-dom';
export default function ImageLink({image, imageAlt, link, hasShadow, hasBackground, hasLink}) {
    

    let className = hasShadow ? "image-link-image shadowed" : "image-link-image";
    let divName =  hasBackground ? "image-link background" : "image-link no-background";
    if (!hasLink) {
        divName += " no-link"
    }

    if (link) {
        var output = <Link className={divName} to={link}><img className={className} src={image} alt={imageAlt}/></Link>
    }
    else {
        output = <div className={divName}><img className={className} src={image} alt={imageAlt}/></div>
    }

    return (
        <>
        {output}
        </>
        
        // <Link className={divName} to={link}><img className={className} src={image} alt={imageAlt}/></Link>
        
        )
        
    
}