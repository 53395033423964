import './Books.css'
import BookLayout from "./book-layout/BookLayout";
// import coverArt from "../../../../assets/images/SELFIES Digital Cover Final.png"
import { useEffect } from "react";
export default function BooksContent({bookList}) {
    //get stored list of books

    useEffect(() => {
        document.title = 'Books - Scott A. Young';
      }, []);

    let bookElements = bookList.map((element, i, {length}) => {
        let isLast = length - 1 === i;
        let pathToPage = "/books/" + element.bookName.toLowerCase();
        let hasLink = true;
        if (!isLast) {
            return (
                <>
                    <BookLayout id={element.id} bookName={element.bookName} subtitle={element.subtitle} coverImage={element.coverImage} blurb={element.blurb} coverArtLink={pathToPage} last={true} hasLink={hasLink} blurbNoVanish={false}/>
                    <div className="page-spacer books-spacer"></div>
                </>)
        }
        else {
            return (
            <BookLayout id={element.id} bookName={element.bookName} subtitle={element.subtitle} coverImage={element.coverImage} blurb={element.blurb} coverArtLink={pathToPage} last={true} hasLink={hasLink} blurbNoVanish={false}/>
            )
        }
        
    });

    return (
        <>
        {bookElements}
        </>
    )
}