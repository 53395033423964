import NavLinkList from "../../header/NavigationLink/NavLinkList"

export default function FooterColumn({columnTitle, columnLinkList, columnOtherElements}) {

    var colTitle = <h3 className="column-title">{columnTitle}</h3>
    
    if (columnLinkList) {
        var colLinkList = <div className="column-link-list"><NavLinkList linkedPages={columnLinkList} isLight={false}/></div>
    }

    return (
        <div className="footer-column">
            {colTitle}
            {colLinkList}
            {columnOtherElements}
            <hr className="column-break"/>
        </div>
    )
}