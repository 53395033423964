import ContactForm from "../../forms/contact-form/ContactForm";
import { useEffect } from "react";

export default function ContactContent() {
    useEffect(() => {
        document.title = 'Contact - Scott A. Young';
      }, []);

      return (
        <>
            <ContactForm />
        </>
      )
}