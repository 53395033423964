import './Header.css';
// import HeaderHeroImage from './header-hero-image/HeaderHeroImage';
import NavLinkList from './NavigationLink/NavLinkList';
// import background from '../../assets/images/headerBackground3.svg'
import SocialLinkList from '../social-icon/SocialIconList';
import BlogUpdate from '../forms/blog-updates/BlogUpdate';
import Hamburger from 'hamburger-react';
import shortStoryAd from '../../assets/images/shortStoryAd.jpg'

import { useEffect, useState } from 'react';

function Header({ linkedPages, linkedSocials }) {
    // VOYAGER INTO THE REALM WHERE QUANTUM REALITY<br/> AND CONSCIOUSNESS MEET
    const [isHamburgerOpen, setOpen] = useState(false);
    const [popupDisplayed, setDisplayed] = useState(false)
    useEffect (() => {
        setOpen(false)
    }, []);

    let tagline = "Classic Sci-Fi with a Contemporary Twist"
    tagline = tagline.toUpperCase();

    const handleHamburgerLinkClicked = () => {
        setOpen(false);
    };

    let headerHamburgerClassName = "header-hamburger"
    headerHamburgerClassName += isHamburgerOpen ? " fixed" : " absolute"


    const handleClosePopup = () => {
        setDisplayed(false)
    }

    const handleOpenPopup = () => {
        setDisplayed(true)
    }

    return (
        <>

            <header className="App-header">
                
                
                
                
                <section>
                    <div className='links header-left-links' >
                    <NavLinkList linkedPages={linkedPages} isLight={true} isFlex={false}/>
                    {/* <div id="header-spacer"></div> */}
                    
                    </div>
                    
                    <div className="header-author-title">
                        <h1 className="author-name">SCOTT A. YOUNG</h1>
                        
                        {/* <h2 className="author-subtitle">AUTHOR</h2> */}
                        <h3 className="author-tagline">{tagline}</h3>
                        {/* <HeaderHeroImage /> */}

                        <button className="btn subscribe" onClick={handleOpenPopup}>Click Here for a Free Short Story</button>
                    </div>                

                    
                    <div className="links header-right-links">
                        <SocialLinkList  linkedSocials={linkedSocials} isFlex={false}/>
                    </div>

                    
                </section>
                
                <section className="header-hamburger-menu">
                    <div className={headerHamburgerClassName}>
                        <Hamburger toggled={isHamburgerOpen} toggle={setOpen} />
                    </div>
                {isHamburgerOpen && 
                    
                        <div className="header-hamburger-inner-menu">
                        <NavLinkList linkedPages={linkedPages} isLight={true} isFlex={false} handleLinkClicked={handleHamburgerLinkClicked}/>
                        <SocialLinkList  linkedSocials={linkedSocials} isFlex={true}/>
                            <div className="header-blog-update">
                                <p>Join My Mailing List</p>
                                <BlogUpdate/>
                            </div>
                        </div>
                    
                }
                </section>

                {popupDisplayed && <div className="header-blog-update modal">
                            <img src={shortStoryAd} alt="The Other Woman. A tale from the Manifold series."></img>
                            <button className='close' onClick={handleClosePopup}>X</button>
                            <p className='header-blog-update-text'>Claim your exclusive free copy of <em>The Other Woman </em>and recieve new release updates by joining my Mailing List.</p>
                            <BlogUpdate/>
                            <p className="disclaimer-label">You can unsubscribe at anytime.</p>
                </div>}
                
                
                
            </header>
            
            
        </>
    )
}

export default Header;