import './App.css';

import Page from './components/pages/page/Page';

import IndividualBook from './components/pages/page-content/books/indivdual-book/IndividualBook';
import IndividualBlogPost from './components/pages/page-content/blog/individual-blog-post/IndividualBlogPost';

import BooksContent from './components/pages/page-content/books/Books';
import HomeContent from './components/pages/page-content/home/Home';
import AboutContent from './components/pages/page-content/about/About';
import ContactContent from './components/pages/contact/Contact';
import BlogContent from './components/pages/page-content/blog/Blog'

import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import { bookList } from '.';
import { blogList } from '.';
import EmailLandingPage from './components/pages/page-content/email-landing-page/EmailLandingPage';

export const linkedPages = [
  { id: 0, name: "Home", link: "/" },
  { id: 1, name: "Books", link: "/books" },
  { id: 2, name: "About", link: "/about" },
  { id: 3, name: "Blog", link: "/blog" },
  { id: 4, name: "Contact", link: "/contact" },
  
];

export const linkedSites = [
  { id: 0, name: "Facebook", link: "https://www.facebook.com/profile.php?id=100063447138907", icon: "https://scottayoungauthor.com/assets/icons8-facebook.svg" },
  { id: 1, name: "Twitter X", link: "https://Twitter.com/YoungSA_Author", icon: "https://scottayoungauthor.com/assets/icons8-twitterx.svg" },
  { id: 2, name: "Instagram", link: "https://www.instagram.com/scottayoungauthor/", icon: "https://scottayoungauthor.com/assets/icons8-instagram.svg" },
  { id: 3, name: "GoodReads", link: "https://www.goodreads.com/author/show/48194506.Scott_A_Young", icon: "https://scottayoungauthor.com/assets/icons8-goodreads.svg"}
];

function App() {
// console.log(bookList)
// console.log(blogList)

  useEffect(() => {
    document.title = 'Scott A. Young';
  }, []);
  


  let bookListFiltered = bookList.filter((element) => {
    return JSON.parse(element.hidden) === false || JSON.parse(element.hidden) === 0
  })
  // console.log(bookListFiltered);
  const indivudualBooks = bookListFiltered.map((element) => {
    let pathToPage = "/books/" + element.bookName.toLowerCase();
    let bookPage = <Page content={<IndividualBook book={element} />}/>
    return <Route exact path={pathToPage} element={bookPage} />
  })

  let blogPostsFiltered = blogList.filter((element) => {
    return JSON.parse(element.hidden) === false || JSON.parse(element.hidden) === 0
  })
  const indivudualBlogPosts = blogPostsFiltered.map((element, i, {length}) => {
    let nextPost;
    let prevPost;
    if (blogList[i-1]) {
      prevPost = blogList[i-1];
    }
    if (blogList[i+1]) {
      nextPost = blogList[i+1];
    }
    let pathToPage = "/blog/" + element.title.toLowerCase().replace(" ", "-");
    let blogPage = <Page content={<IndividualBlogPost blogPost={element} nextPost={nextPost} prevPost={prevPost}/>} />
    return <Route exact path={pathToPage} element={blogPage} />
  })


  let homePage = <Page content={<HomeContent comingSoonBook={bookList[0]} bookList={bookListFiltered} />} />;
  let booksPage = <Page content={<BooksContent bookList={bookListFiltered} />} />;
  let aboutPage = <Page content={<AboutContent isHomePage={false}/>} />;
  let contactPage = <Page content={<ContactContent />} />
  let blogPage = <Page content={<BlogContent blogPosts={blogPostsFiltered}/>} />
  let emailLandingPage = <EmailLandingPage />

  return (
    <>
      <Router>
        <ScrollToTop />
        

        <Routes>
          <Route exact path="/" element={homePage} />
          <Route exact path="/books" element={booksPage} />
          <Route exact path="/about" element={aboutPage} />
          <Route exact path="/contact" element={contactPage} />
          <Route exact path="/blog" element={blogPage} />
          <Route exact path="/exclusivecontent" element={emailLandingPage} />
          {indivudualBooks}
          {indivudualBlogPosts}
        </Routes>
        
      </Router>


    </>

  );
}


export default App;
