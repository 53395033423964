import './Page.css'
import { linkedPages, linkedSites } from '../../../App';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

function Page ({content}) {

    
    return (
        <>
        <Header linkedPages={linkedPages} linkedSocials={linkedSites} />
        <main>
        {content}
        </main>
        <Footer linkedPages={linkedPages} linkedSocials={linkedSites} />
        </>
    )
}
export default Page;