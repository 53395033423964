import BookLayout from "../books/book-layout/BookLayout";
import './Home.css'
import { useEffect } from "react";
import AboutContent from "../about/About";

import Chyron from "../../../chyron/Chyron";

// import BooksContent from "../books/Books";
// import BlogUpdate from "../../../forms/blog-updates/BlogUpdate";
export default function HomeContent({ comingSoonBook, bookList }) {
    useEffect(() => {
        document.title = 'Home - Scott A. Young';
    }, []);


    let comingSoonLayout = <BookLayout id={comingSoonBook.id} bookName={comingSoonBook.bookName.toUpperCase()} subtitle={comingSoonBook.subtitle} coverImage={comingSoonBook.coverImage} blurb={comingSoonBook.blurb} coverArtLink={"/books/" + comingSoonBook.bookName.toLowerCase()} last={true} hasLink={true} blurbNoVanish={false} linksToBuy={comingSoonBook.linksToBuy} />

    const blurbs = [
        "<h2 id=\"coming-soon-title\">Available Now!</h2>",
        {id: 0, shortBlurb: "5 out of 5...A compelling, series-opening science fiction novel.", source: "<em>Forward</em> Clarion Reviews"},
        {id: 1, shortBlurb: "Packed with intrigue, action and creative energy...", source: "BlueInk Review"},
        {id: 2, shortBlurb: "...breathlessly paced..brims with...action and imaginative plot twists...", source: "BlueInk Review"},
        {id: 3, shortBlurb: "Explosive, exciting conclusion...", source: "<em>Foward</em> Clarion Reviews"}
        
    ]

    return (
        <>
            <div className="home-page">


                <div className="coming-soon-container">

                    
                    <Chyron displayItems={blurbs}/>

                    <div className="coming-soon-book">


                        {comingSoonLayout}

                    </div>
                </div>
                <div className="page-spacer"></div>

                <div className="home-about-content">
                    <AboutContent isHomePage={true} />
                </div>
                {/* <h1 id="other-books-title">Books</h1>
            <BooksContent bookList={bookList}/> */}
            </div>
        </>

    )
}