import './NavLinkList.css';
import NavigationLink from "./NavigationLink";


export default function NavLinkList({linkedPages, isLight, isFlex, handleLinkClicked}) {
    let className = isFlex ? " nav-flex" : " nav-block"
    let pages = linkedPages.map(function (element) {
        return <NavigationLink key={element.id} id={element.id} linkPath={element.link} linkName={element.name} isLight={isLight} handleClicked={handleLinkClicked}/>
    });
    return (
        <ul className={"nav-links" + className}>
            {pages}
        </ul>
    )
}