import './NavigationLink.css'
import {Link} from "react-router-dom"

function NavigationLink({linkPath, linkName, isLight, id, key, handleClicked}) {
    // console.log("linkPath", linkPath)
    // console.log("linkName", linkName)
    // console.log(isLight)
    let className = isLight ? " light" : " dark"
    
    return (
        <li key={key}>
        <Link to={linkPath} className={"nav-link" + className} onClick={handleClicked}>{linkName}</Link>
            {/* <a href={linkPath}>{linkName}</a>  */}
        </li>
    )
}

export default NavigationLink