import './BookLayout.css'
import ImageLink from '../../../../image-link/ImageLink';
import parse from 'html-react-parser';
function BookLayout({ bookName, subtitle, coverImage, blurb, coverArtLink, last, blurbHtml, hasLink, blurbNoVanish, linksToBuy }) {
   let windowWidth = window.innerWidth;

   // console.log(last);
   let paras;
   if (blurb) {
      if (windowWidth < 1000 && !blurbNoVanish) {
         

         // Function to get index of occurrence
         function getIndex(str, substr, ind) {
            let Len = str.length,
               i = -1;
            while (ind-- && i++ < Len) {
               i = str.indexOf(substr, i);
               if (i < 0) break;
            }
            return i;
         }

         let loc = getIndex(blurb, "\n", 3); //get the 3rd occurance of \n

         blurb = blurb.slice(0, loc).trim() + "..."

      }
      let blurbParagraphs = blurb.split("\n")
      paras = []
      for (let blurbParagraph of blurbParagraphs) {
         // console.log(blurbParagraph)
         let para = "<p>".concat(blurbParagraph, "<br/></p>")
         // console.log(parse(para))
         paras.push(parse(para))
      }

   }
   else if (blurbHtml) {
      paras = blurbHtml;
   }


   let imageAlt = hasLink ? bookName + " cover art" : bookName
   let blurbClass = "blurb no-vanish"

   function tryParseJSONObject(jsonString) {
      try {
        var o = JSON.parse(jsonString);
    
        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
          return o;
        }
      }
      catch (e) { }
    
      return false;
    };
    // console.log(book.linksToBuy);
  if (linksToBuy && linksToBuy.length > 0) {
      
      let parsedLinks = tryParseJSONObject(linksToBuy);
      if (!parsedLinks) {
          parsedLinks = tryParseJSONObject(JSON.stringify(linksToBuy));
      }
      console.log(parsedLinks)
      var links = parsedLinks.map((element) => {
          return (<form className="link-to-buy" action={element.link} target="_blank"><button className="link-btn" type="submit">{element.name.charAt(0).toUpperCase() + element.name.slice(1)}</button></form>)
      })
      // var coverArtLink = parsedLinks[0].link;
      // var linkDestinationName = book.linksToBuy[0].name;
      var hasLinkToBuy = true;
  }
  else {
      hasLinkToBuy = false;
  }

   return (
      <section className="book-layout">

         <div className="book-layout-contents">
            <div className="book-image">
               <ImageLink imageAlt={imageAlt} image={coverImage} link={coverArtLink} hasShadow={true} hasBackground={false} hasLink={hasLink} />
            </div>
            <div className="book-text">
               {/* <h1 className="book-title">{bookName}</h1> */}
               {/* {subtitle && <h2 className='book-subtitle'>{subtitle}</h2>} */}
               <div className={blurbClass}>{paras}</div></div>


         </div>
         {hasLinkToBuy && <h2 className='link-to-buy-title'>Get a Copy</h2>}
            {links}
         {!last && <hr />}

      </section>
   );

}

export default BookLayout;
